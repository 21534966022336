import gql from 'graphql-tag';

export default gql`
mutation CreateProject($obj: projects_insert_input!) {
  insert_projects_one(object: $obj) {
    project_id
    group_id
    parent_folder_id
    name
    slug
    order_index
  }
}
`;
