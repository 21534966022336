import gql from 'graphql-tag';

export default gql`
query getThemeDataByThemeName($themeName: String!) {
  themes(where: {name: {_eq: $themeName}}) {
    theme_id
    default_settings
  }
}
`;
