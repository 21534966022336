import gql from 'graphql-tag';

export default gql`
mutation updateItemOrderIndex($obj: updateItemOrderIndexInput!) {
  updateItemOrderIndex(object: $obj) {
    folder_id
    project_id
    new_order_index
  }
}
`;
