import gql from 'graphql-tag';

export default gql`
query getAllTemplateCollections {
  template_collections {
    template_collection_id
    name
  }
}
`;
