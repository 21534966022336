var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-modal", {
        attrs: {
          id: "modal-component-project",
          size: "lg",
          "no-close-on-backdrop": "",
          "header-class": "d-block",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("webmag.choose_project_template")))
                ]),
                _c(
                  "b-button",
                  {
                    staticClass: "position-absolute",
                    on: { click: _vm.goBackInModal }
                  },
                  [_c("b-icon", { attrs: { icon: "arrow-left-circle-fill" } })],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "border-right-light-grey",
                        attrs: { cols: "12", sm: "3" }
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "project_templates pl-2 mt-5 pb-5" },
                          [
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  class: {
                                    active: _vm.selectedSharedItem === null
                                  },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      return _vm.loadEmptyContent()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "modals.createItem.project.emptyProject"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]),
                            _vm._l(_vm.sharedProjects, function(item, key) {
                              return _c(
                                "li",
                                {
                                  key: `${item.type}-${key}`,
                                  staticClass: "nav-item"
                                },
                                [
                                  item.type === "page"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "nav-link",
                                          class: { active: item.isActive },
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadPreviewContent(key)
                                            }
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(item.name) + " ")]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "d-flex flyout py-2",
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleFlyout(key)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "pl-3 cursor-pointer"
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          item.showChildren
                                            ? _c("b-icon", {
                                                staticClass: "icon",
                                                attrs: {
                                                  icon: "chevron-down",
                                                  "aria-hidden": "true"
                                                }
                                              })
                                            : _c("b-icon", {
                                                staticClass: "icon",
                                                attrs: {
                                                  icon: "chevron-right",
                                                  "aria-hidden": "true"
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                  item.showChildren &&
                                  item.children &&
                                  item.children.length
                                    ? _c(
                                        "ul",
                                        { staticClass: "pl-3" },
                                        _vm._l(item.children, function(
                                          childPage,
                                          index
                                        ) {
                                          return _c(
                                            "li",
                                            { key: index, staticClass: "pb-2" },
                                            [
                                              childPage.type === "page"
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "py-0 nav-link",
                                                      class: {
                                                        active:
                                                          childPage.isActive
                                                      },
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.loadChildPreviewContent(
                                                            key,
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            childPage.name
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e()
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ]
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "12", sm: "9" } },
                      [
                        _vm.selectedSharedItem === null
                          ? _c(
                              "div",
                              { staticClass: "m-5 pb-5" },
                              [
                                _c("h4", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "modals.createItem.project.emptyProject"
                                      )
                                    )
                                  )
                                ]),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "modals.createItem.project.emptyProjectDesc"
                                      )
                                    )
                                  )
                                ]),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-0 ml-0 green-button",
                                    on: { click: _vm.showProjectNameModal }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "plus-circle-fill",
                                        "aria-hidden": "true",
                                        scale: "1"
                                      }
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("webmag.select")) +
                                        " "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm.groupId
                          ? _c("modal-content-show-item-preview", {
                              key: _vm.previewKey,
                              attrs: {
                                "group-id": _vm.groupId,
                                "group-slug": _vm.groupSlug,
                                "group-domain": _vm.groupDomain,
                                "group-path": _vm.groupPath,
                                "parent-item": _vm.parentItem,
                                "parent-slug-path": _vm.parentSlugPath,
                                "shared-item": _vm.selectedSharedItem
                              },
                              on: {
                                "open-name-modal": _vm.showProjectNameModal
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _c("b-modal", {
        ref: "modal-component-project-name",
        attrs: {
          id: "modal-component-project-name",
          "footer-class": "flex-nowrap",
          centered: "",
          "no-stacking": ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("modals.createItem.project.insertProjectName")
                    )
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("modal-choose-name-content", {
                  attrs: {
                    "group-id": _vm.groupId,
                    "item-name": _vm.itemName,
                    "item-slug": _vm.itemSlug,
                    "group-slug": _vm.groupSlug,
                    "child-slugs": _vm.childSlugs,
                    "item-type": "project"
                  },
                  on: {
                    "update:itemName": function($event) {
                      _vm.itemName = $event
                    },
                    "update:item-name": function($event) {
                      _vm.itemName = $event
                    },
                    "update:itemSlug": function($event) {
                      _vm.itemSlug = $event
                    },
                    "update:item-slug": function($event) {
                      _vm.itemSlug = $event
                    },
                    "enter-pressed": _vm.confirmOkModalByEnterKey
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: {
                      disabled: !_vm.isNameSet,
                      variant: "primary",
                      block: ""
                    },
                    on: { click: _vm.handleOk }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _vm.showAcknowledgeModal
        ? _c("modal-acknowledge", {
            attrs: { "action-type": _vm.actionType, "page-type": "project" },
            on: { "close-acknowledge-modal": _vm.closeAcknowledgeModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }